<div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="8px" class="vip-rewards-container">
	<mat-card fxFlex>
		<mat-card-title>Your Point Balance</mat-card-title>
		<mat-card-content>
			<p style="text-align: center">
				<span class="mat-headline">{{vipRewardsPoints}}</span> points
			</p>
		</mat-card-content>
	</mat-card>

	<mat-card flex>
		<mat-card-title>Service and Car Wash Rewards</mat-card-title>
		<mat-card-content fxLayout="row">
			<mat-list fxFlex="45px" class="points-list">
				<mat-list-item>
					<b>Points</b>
				</mat-list-item>

				<ng-container *ngFor="let reward of serviceRewards">
					<mat-divider></mat-divider>
					<mat-list-item>
						{{reward.points}}
					</mat-list-item>
				</ng-container>
			</mat-list>

			<mat-divider class="point-reward-divider" [vertical]="true"></mat-divider>

			<mat-list>
				<mat-list-item>
					<b>Reward</b>
				</mat-list-item>

				<ng-container *ngFor="let reward of serviceRewards">
					<mat-divider></mat-divider>
					<mat-list-item>
						{{reward.description.primary}} {{reward.description.secondary}}
					</mat-list-item>
				</ng-container>

			</mat-list>
		</mat-card-content>

		<div class="mat-caption disclaimer">
			* Car Wash Rewards available at locations with on-site car wash
		</div>
	</mat-card>

	<mat-card flex>
		<mat-card-title>Express Lube Oil Change Rewards</mat-card-title>
		<mat-card-content>
			<mat-list fxFlex="45px" class="points-list">
				<mat-list-item>
					<b>Points</b>
				</mat-list-item>

				<ng-container *ngFor="let reward of oilChangeRewards">
					<mat-divider></mat-divider>
					<mat-list-item>
						{{reward.points}}
					</mat-list-item>
				</ng-container>
			</mat-list>

			<mat-divider class="point-reward-divider" [vertical]="true"></mat-divider>

			<mat-list>
				<mat-list-item>
					<b>Reward</b>
				</mat-list-item>

				<ng-container *ngFor="let reward of oilChangeRewards">
					<mat-divider></mat-divider>
					<mat-list-item>
						{{reward.description.secondary}}
					</mat-list-item>
				</ng-container>
			</mat-list>
		</mat-card-content>
	</mat-card>
</div>
