<div fxFill fxLayout="column">
	<mat-sidenav-container fxFlex>
		<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center" >
			<button mat-icon-button (click)="sidenav.toggle()">
				<mat-icon>menu</mat-icon>
			</button>

			<div class="mat-title">{{navTitle | async}}</div>

			<div fxFlex></div>

			<!-- 			   ngx-analyticsOn="click"
			   angularticsAction="Opened Website"
			   angularticsLabel="Company Logo - Sidenav"
			   angularticsCategory="Store" -->
			<a class="main-toolbar-store-logo"
			   href="{{storeInfo?.StoreWebAssets?.WebAddress}}"
			   target="_blank"

			>
				<img *ngIf="storeInfo?.StoreWebAssets?.LogoSmall"
				     height="56"
				     [src]="storeInfo?.StoreWebAssets?.LogoSmall" />
			</a>

			<div fxFlex></div>
		</mat-toolbar>

		<mat-sidenav #sidenav mode="over">
			<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
				<!--
								   ngx-analyticsOn="click"
				   angularticsAction="Opened Website"
				   angularticsLabel="Company Logo - Nav Bar"
				   angularticsCategory="Store"
				   -->
				<a href="{{storeInfo?.StoreWebAssets?.WebAddress}}"
				   target="_blank"

				>
					<img *ngIf="storeInfo?.StoreWebAssets?.LogoSmall"
					     height="56"
					     [src]="storeInfo?.StoreWebAssets?.LogoSmall" />
				</a>

				<!--<div>-->
					<!--<a mat-button mat-icon-button [href]="storeInfo?.PhoneNumberToSMS?.NumberForWebLink | safeUrl">-->
						<!--<mat-icon>message</mat-icon>-->
					<!--</a>-->
					<!--<a mat-button mat-icon-button [href]="storeInfo?.PhoneNumberToCall?.NumberForWebLink | safeUrl">-->
						<!--<mat-icon>call</mat-icon>-->
					<!--</a>-->
				<!--</div>-->
			</mat-toolbar>
			<mat-nav-list>
				<!-- Account actions -->
				<div fxLayout="row" fxLayoutAlign="space-between center">
					<h3 mat-subheader>Account</h3>
					<div fxFlex></div>

					<button mat-icon-button (click)="sidenav.toggle()" color="accent">
						<mat-icon>chevron_left</mat-icon>
					</button>
				</div>
				<a mat-list-item routerLink="/account" (click)="sidenav.toggle()">
					<mat-icon mat-list-icon color="primary">account_circle</mat-icon>
					<h4 mat-line>{{customerName}}</h4>
<!--					<p mat-line>{{primaryEmail}}</p>-->
				</a>
				<a mat-list-item routerLink="/rewards" (click)="sidenav.toggle()">
					<mat-icon mat-list-icon color="primary">card_membership</mat-icon>
					<h4 mat-line>VIP Rewards</h4>
					<p mat-line>{{pointsBalance}} points</p>
				</a>

				<mat-divider></mat-divider>

				<!-- General nav  -->
				<a mat-list-item routerLink="/vehicles" (click)="sidenav.toggle()">
					<mat-icon mat-list-icon color="primary">directions_car</mat-icon>
					<h4 mat-line>My Vehicles</h4>
				</a>
				<a mat-list-item routerLink="/schedule" (click)="sidenav.toggle()">
					<mat-icon mat-list-icon color="primary">event</mat-icon>
					<h4 mat-line>Schedule an Appointment</h4>
				</a>
				<a mat-list-item routerLink="/coupons" (click)="sidenav.toggle()">
					<mat-icon mat-list-icon color="primary">loyalty</mat-icon>
					<h4 mat-line>Coupons</h4>
				</a>
				<a mat-list-item routerLink="/history" (click)="sidenav.toggle()">
					<mat-icon mat-list-icon color="primary">history</mat-icon>
					<h4 mat-line>Vehicle History</h4>
				</a>

				<mat-divider></mat-divider>

				 <!--Contact actions-->
				<h3 mat-subheader>Contact</h3>

				<!--
								   ngx-analyticsOn="click"
				   angularticsAction="Called"
				   angularticsLabel="Sidenav"
				   angularticsCategory="Store"
			   -->
				<a mat-list-item
				   [href]="storeInfo?.PhoneNumberToCall?.NumberForWebLink | safeUrl">
					<mat-icon mat-list-icon color="primary">call</mat-icon>
					<h4 mat-line>Call Us</h4>
					<p mat-line>{{storeInfo?.PhoneNumberToCall?.Number}}</p>
				</a>
				<!--
								   ngx-analyticsOn="click"
				   angularticsAction="Sent Email"
				   angularticsLabel="Sidenav"
				   angularticsCategory="Store"
			   -->
				<a mat-list-item
				   [href]="storeInfo?.EmailAddress.MailtoLink | safeUrl"
				   target="_top">
					<mat-icon mat-list-icon color="primary">email</mat-icon>
					<h4 mat-line>Email Us</h4>
					<p mat-line>{{storeInfo?.EmailAddress.DisplayValue}}</p>
				</a>
				<!--
								   ngx-analyticsOn="click"
				   angularticsAction="Sent SMS"
				   angularticsLabel="Sidenav"
				   angularticsCategory="Store"
			   -->
				<a mat-list-item
				   [href]="storeInfo?.PhoneNumberToSMS?.NumberForWebLink | safeUrl"
>
					<mat-icon mat-list-icon color="primary">message</mat-icon>
					<h4 mat-line>Text Us</h4>
					<p mat-line>{{storeInfo?.PhoneNumberToSMS?.Number}}</p>
				</a>

				<mat-divider></mat-divider>

				<mat-list-item (click)="logout()">
					<mat-icon mat-list-icon color="primary">exit_to_app</mat-icon>
					<h4 mat-line>Logout</h4>
				</mat-list-item>
			</mat-nav-list>

		</mat-sidenav>
		<!--<mat-sidenav-content>-->
		<ma-loader></ma-loader>

		<div fxLayout="column" class="ma-router-container">
			<router-outlet></router-outlet>
		</div>

		<ma-commit-summary [gitInfo]="gitInfo"></ma-commit-summary>

		<!--</mat-sidenav-content>-->
	</mat-sidenav-container>
</div>

	<!--<div class="ma-print-version-only mat-subheading-2 ma-store-address">-->
		<!--<div>{{storeInfo?.StoreAddress?.Line1}}</div>-->
		<!--<div>{{storeInfo?.StoreAddress?.City}}, {{storeInfo?.StoreAddress?.StateShort}} {{storeInfo?.StoreAddress?.ZIP}}</div>-->
		<!--<div>{{storeInfo?.PhoneNumberToCall?.Number}}</div>-->
	<!--</div>-->
